import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "../components/customNavbar";
import Aboutus from "../views/about";
import WhyOceanmate from "../views/ocean";
import Contact from "../views/contact";
import Footer from "../components/footer";
import Home from "../views/home";
import Eligibility from "../views/eligi";
import Testimonial from "../views/testimonials";
import Scrolltotop from "../components/scrolltotop";

const Router = () => {
  return (
    <BrowserRouter>
      <Scrolltotop />
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<Aboutus />} />
        <Route exact path="/ocean" element={<WhyOceanmate />} />
        <Route exact path="/elgibility" element={<Eligibility />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/testimonials" element={<Testimonial />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default Router;
