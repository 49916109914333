import React from "react";

import Button from "../button";

const Aboutcard = (props) => {
  return (
    <div>
      <div className={`aboutcard p-4 + ${props.className}` } style={{ height: props.height }}>
        <div>
          {props.icon ? (
            <props.icon
              color="#e77000"
              size={33}
              className={props.iconclassName}
            />
          ) : (
            <img src={props.img} />
          )}
          <h4 className="text-uppercase fw-semibold f-15 my-1  ">{props.heading}</h4>
          <p className="text-justify my-3 f-12">{props.desc}</p>
         
        </div>
      </div>
    </div>
  );
};

export default Aboutcard;
