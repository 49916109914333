let accordion = {
  question1: "	What is a Oceanmate marine ticketing online portal?",
  answer1:
    "The Oceanmate marine ticketing online portal is an online platform that allows seafarers, mariners, offshore workers crew managers, agents, shipping and marine companies to purchase tickets for marine travel and air travels. It is one of its kind in India and Asia.",
  question2: "What types of marine travel tickets are available?",
  answer2:
    "Different marine travel tickets are available depending on the type of shipping vessel and the route. Some examples include one-way or round-trip tickets, cabin upgrades, meal packages, and luggage allowances.",
  question3:
    "	How do I book a ticket through Oceanmate marine ticketing online portal?",
  answer3:
    "To book a ticket through a marine travel ticketing system, you typically need to create an account and provide your travel details, such as the date and time of travel, departure and arrival locations, and passenger information. You can then browse available tickets and select the one that meets your needs.",
  question4: "	Can I cancel or modify my ticket after booking?",
  answer4:
    "Oceanmate marine travel ticketing online portal allow customers to cancel or modify their tickets within a certain timeframe and according to certain terms and conditions of airlines. All these real time updates are visible on the Oceanmate centralized dashboard. It is important to read the cancellation and modification policies carefully before booking your ticket.",
  question5:
    " What payment methods are accepted at Oceanmate marine ticketing online portal?",
  answer5:
    "Oceanmate marine ticketing online portal accept major credit cards, debit cards, and online payment systems such as all major UPIs, PayPal etc.",
  question6: "	How do I receive my ticket after booking?",
  answer6:
    "You may receive your ticket via email or by downloading it from the Oceanmate marine ticketing online portal. You can also print your ticket or pick it up at the port.",
  question7: "	What should I do if I have a problem with my ticket or booking?",
  answer7:
    "If you have a problem with your ticket or booking, you should contact the Oceanmate’s customer support team for assistance with 24/7 assistance via call or over e-mail.",
};
export default accordion;
