import React, { useState } from "react";
import "./index.css";
import Button from "../../components/button";
import FeatureCard from "../../components/featureCard";
import { RxCounterClockwiseClock } from "react-icons/rx";
import ship1 from "../../assests/images/background/ship1.jpg"
import ship12 from "../../assests/images/background/ship12.jpeg"
import ship13 from "../../assests/images/background/ship13.jpg"
import ship14 from "../../assests/images/background/ship14.jpg"
import ship15 from "../../assests/images/background/ship15.jpg"
import Team from "../../components/team";
import team1 from "../../assests/images/team/AKSHAY_ANKIT_CEO_OCEANMATE.jpg";
import team2 from "../../assests/images/team/SWATI_RANJAN_DIRECTOR_OCEANMATE.jpg";
import team3 from "../../assests/images/team/NILESH_KUMAR_DIRECTOR_OCEANMATE.jpg";
import team4 from "../../assests/images/team/PIYUSH_KUMAR.jpg";
import team6 from "../../assests/images/team/prashantgyan.jpg";
import about from "../../assests/data/about";
import schedule from "../../assests/images/icons/schedule.svg";
import invoice from "../../assests/images/icons/invoice.svg";
import online from "../../assests/images/icons/online.svg";
import { Modal } from "react-bootstrap";
import TeamDescription from "../../components/modalcontent";
import Teamdata from "../../assests/data/teamdata";
import Teamslider from "../../components/teamslider";
import AOS from "aos";
import Aboutcard from "../../components/aboutcard";


const About = () => {
  AOS.init({ able: "mobile" });
  let [member1, setMember1] = useState(false);
  let [member2, setMember2] = useState(false);
  let [member3, setMember3] = useState(false);
  let [member4, setMember4] = useState(false);
  let [member5, setMember5] = useState(false);

  return (
    <div>
      <div className="container-fluid p-0">
        <div className="bg_about ">
          <div className="text-white">
            <h5 className="text-center  text-primary f-35 ls-6">ABOUT
            US</h5>
            <h2 className=" text-center text-uppercase">
              Sail into convenience with Oceanmate
            </h2>
          </div>
        </div>
       
       
        <div className="container mt-5 pt-5">
          <div className="row" >
            <div className="card col-sm-6  text-center"
            data-aos="fade-right"
            data-aos-offset="150"
            data-aos-delay="20"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-center">
              <img src={ship14} width="100%" height="100%" />
            </div>
            <div className="col-sm-6 ">
              <div className="row">
                <div className="col-sm-10 m-auto">
                  <div className="mt-4"
                  data-aos="fade-left"
                  data-aos-offset="150"
                  data-aos-delay="20"
                  data-aos-duration="600"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-once="true"
                  data-aos-anchor-placement="top-center">
                     <p className=" text-uppercase text-primary fw-bold f-18 ls-6">
                     STORY BEGINS
                    </p>
                    <p className="text-justify">{about.description1}
                    {about.description2}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>

          <div className="container mt-5 pt-5" >
            <div className="row">
              <div className="col-sm-6">
              <div className="col-sm-10 m-auto">
                <div className="mt-4"
                data-aos="fade-right"
                data-aos-offset="150"
                data-aos-delay="20"
                data-aos-duration="600"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-once="true"
                data-aos-anchor-placement="top-center">
                  <p className=" text-uppercase text-primary fw-bold f-18 ls-6">
                  ON GOING ADVENTURE
                    </p>
                  <p className="text-justify">
                  {about.description3}
                  {about.description4}</p>
                
 </div>
 </div>
              </div>
              <div className="card col-sm-6  text-center"
              data-aos="fade-left"
              data-aos-offset="150"
              data-aos-delay="20"
              data-aos-duration="600"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-anchor-placement="top-center">
                <img src={ship15} width="100%" height="100%"/>
              </div>
            </div>
          </div>

        
        
        
        <div className="container mt-5 pt-5">
          <div className="row" >
            <div className="card col-sm-6  text-center"
            data-aos="fade-right"
            data-aos-offset="150"
            data-aos-delay="20"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-center">
              <img src={ship12} width="100%" height="100%" />
            </div>
            <div className="col-sm-6 ">
              <div className="row">
                <div className="col-sm-10 m-auto">
                  <div className="mt-4"
                  data-aos="fade-left"
                  data-aos-offset="150"
                  data-aos-delay="20"
                  data-aos-duration="600"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-once="true"
                  data-aos-anchor-placement="top-center">
                    <p className="text-primary fw-bold f-18 ls-6">
                      {about.subheading1}
                    </p>
                    <p className="text-justify">{about.description5}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>

          <div className="container mt-5 pt-5" >
            <div className="row">
              <div className="col-sm-6">
              <div className="col-sm-10 m-auto">
                <div className="mt-4"
                data-aos="fade-right"
                data-aos-offset="150"
                data-aos-delay="20"
                data-aos-duration="600"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-once="true"
                data-aos-anchor-placement="top-center">
                  <p className="text-primary fw-bold f-18 ls-6">
                    {about.subheading2}</p>
                  <p className="text-justify">
                  {about.description6}</p>
                
 </div>
 </div>
              </div>
              <div className="card col-sm-6  text-center"
              data-aos="fade-left"
              data-aos-offset="150"
              data-aos-delay="20"
              data-aos-duration="600"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-anchor-placement="top-center">
                <img src={ship13} width="100%" height="100%"/>
              </div>
            </div>
          </div>
         
         
          <div className="container ">
            <div className="col-sm-12 text-center  mt-7">
              <p className="f-18 text-uppercase  text-primary ls-base fw-bold ">
               {about.heading3}
              </p>
              <h1 className="f-16  text-secondary text-uppercase ">
                 {about.subheading3}
               
              </h1>
            </div>
           
           
            <div className="row mt-5">
              <div className="col-sm-3"
              data-aos="fade-up"
              data-aos-offset="150"
              data-aos-delay="20"
              data-aos-duration="600"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-anchor-placement="top-center"
            
            >
              
               <Aboutcard
                  img={schedule}
                  heading={about.cardheading1}
                  desc={about.carddescription1}
                  height="350px"
                  className="bookus_card"
              />
              </div>
              <div className="col-sm-3"
               data-aos="fade-up"
               data-aos-offset="150"
               data-aos-delay="20"
               data-aos-duration="600"
               data-aos-easing="ease-in-out"
               data-aos-mirror="true"
               data-aos-once="true"
               data-aos-anchor-placement="top-center">
                <Aboutcard
                  icon={RxCounterClockwiseClock}
                  heading={about.cardheading2}
                  desc={about.carddescription2}
                  height="350px"
                  className="bookus_card f-15"
               
                />
              </div>
              <div className="col-sm-3"
               data-aos="fade-up"
               data-aos-offset="150"
               data-aos-delay="20"
               data-aos-duration="600"
               data-aos-easing="ease-in-out"
               data-aos-mirror="true"
               data-aos-once="true"
               data-aos-anchor-placement="top-center">
               
                <Aboutcard
                  img={invoice}
                  heading={about.cardheading3}
                  desc={about.carddescription3}
                  height="350px"
                  className="bookus_card f-15"
                />
              </div>
              <div className="col-sm-3"
               data-aos="fade-up"
               data-aos-offset="150"
               data-aos-delay="20"
               data-aos-duration="600"
               data-aos-easing="ease-in-out"
               data-aos-mirror="true"
               data-aos-once="true"
               data-aos-anchor-placement="top-center">
               
                <Aboutcard
                  img={online}
                  heading={about.cardheading4}
                  desc={about.carddescription4}
                  height="350px"
                  className="bookus_card f-15"
                />
              </div>
            </div>
          </div>

          <div className="container-fluid mt-5"
          data-aos="fade-down"
          data-aos-offset="150"
          data-aos-delay="20"
          data-aos-duration="600"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top-center">

            <div className="row p-5">
              <div className="col-sm-9 m-auto">
                <h6 className="text-primary ls-base fw-bold f-18 text-uppercase text-center">
                  Meet the amazing 
                </h6>
                <h1 className="text-secondary text-uppercase text-center mt-3 f-18">{about.subheading4}</h1>
                <p className="f-18 text-justify mt-4">
                 {about.description7 }
                </p>
              </div>
              </div>
              

              
              <div className="row">
                <div className="col-sm-1"></div>
              <div className="col-sm-2 mt-2">
                <Team
                  img={team1}
                  name={"Akshay Ankit"}
                  designation={"CEO"}
                  onClick={() => {
                    setMember1(true);
                  }}
                />
              </div>

              <div className="col-sm-2 mt-2">
                <Team
                  img={team2}
                  name={"Swati Ranjan"}
                  designation={"Director"}
                  onClick={() => {
                    setMember2(true);
                  }}
                />
              </div>

              <div className="col-sm-2 mt-2">
                <Team
                  img={team3}
                  name={"Nilesh Kumar"}
                  designation={"Director"}
                  onClick={() => {
                    setMember3(true);
                  }}
                />
              </div>
          
              <div className="col-sm-2 mt-2">
                
                <Team
                  img={team4}
                  name={"Piyush Kumar"}
                  designation={"Director"}
                  onClick={() => {
                    setMember4(true);
                  }}
                />
              </div>
              
              <div className="col-sm-2 mt-2">
                <Team
                  img={team6}
                  name={"Prashant Gyan"}
                  designation={"Director"}
                  onClick={() => {
                    setMember5(true);
                  }}
                />
              </div>
              <div className="col-sm-1"></div>
              </div>
          </div>
            
          
          
        
        <div
          className="contactus_parallax3 mt-7"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
           

             <div className="container ">
              <div className="row mb-5">
                <div className="col-sm-7 ">
                  <h1 className="f-45 text-white text-capitalize ">
                  Effortlessly navigate the seas with us
                  </h1>
                </div>

              
                  <Button
                    textTransform="uppercase"
                    padding="12px"
                    fw="700"
                    border="1px solid  #e77000"
                    bg=" #e77000"
                    color="#ffffff"
                    transition="all 0.5s"
                    className="custom_btn  mt-3 "
                  >
                    READ MORE
                  </Button>
             
              </div>
            </div>
        </div>
      </div>
      {/* member 1 modal */}
      <Modal show={member1} onHide={() => setMember1(false)} size="lg text-justify">
        <Modal.Header closeButton />
        <Modal.Body>
          <TeamDescription
            img={team1}
            name={Teamdata.member1}
            position={Teamdata.designation1}
            info={Teamdata.desc1}
          />
        </Modal.Body>
      </Modal>
      {/* member2 modal */}
      <Modal show={member2} onHide={() => setMember2(false)} size="lg text-justify">
        <Modal.Header closeButton />
        <Modal.Body>
          <TeamDescription
            img={team2}
            name={Teamdata.member2}
            position={Teamdata.designation2}
            info={Teamdata.desc2}
          />
        </Modal.Body>
      </Modal>
      {/* member3 modal */}
      <Modal show={member3} onHide={() => setMember3(false)} size="lg text-justify">
        <Modal.Header closeButton />
        <Modal.Body>
          <TeamDescription
            img={team3}
            name={Teamdata.member3}
            position={Teamdata.designation3}
            info={Teamdata.desc3}
          />
        </Modal.Body>
      </Modal>
      {/* member4 modal */}
      <Modal show={member4} onHide={() => setMember4(false)} size="lg text-justify">
        <Modal.Header closeButton />
        <Modal.Body>
          <TeamDescription
            img={team4}
            name={Teamdata.member4}
            position={Teamdata.designation4}
            info={Teamdata.desc4}
          />
        </Modal.Body>
      </Modal>
      {/* member5 modal */}
      <Modal show={member5} onHide={() => setMember5(false)} size="lg text-justify">
        <Modal.Header closeButton />
        <Modal.Body>
          <TeamDescription
            img={team6}
            name={Teamdata.member5}
            position={Teamdata.designation5}
            info={Teamdata.desc5}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default About;
