let seafarers = {
  heading1: 'SEAFARERS TRAVEL',
  description1:
    'With a dedicated team at Oceanmate and an automated ticketing dashboard, we manage seafarers’ travel plans, which is essential to the smooth operation of the shipping industry.',
 
    heading2: 'COMMERCIAL CREW MEMBERS',
  description2:
    'Oceanmate has created a customised automated ticketing platform for Marine Air Travel Services while keeping in mind the structure of commercial ships and their providers. We provide all the travel options from the workplace to homes for all the commercial crew members.',
  
    heading3: 'CRUISE LINE CREW',
  description3:
    'Oceanmate’s automated ticketing platform is integrated with an SBT (System Booking Tool) module. Oceanmate will help crew members of cruise ships travel with simplicity and at the lowest available flight costs.',
 
    heading4: 'CREW MANAGER',
  description4:
    'We understand the necessity for crew managers and agents to manage the team as a whole extremely effectively. Even crew hiring is handled by them. To help the crewing agencies deliver a cost-effective service, Oceanmate offers them discounted flight ticket prices.',
 
    heading5: 'OFFSHORE WORKERS',
  description5:
    'To get their offshore workers to and from work, many offshore marine companies need to provide the finest service possible. Oceanmate recognises the value of their comfort and provides them with the best and most efficient service in the marine industry.',
}

export default seafarers
