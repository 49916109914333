import React from "react";
import Carousel from "react-multi-carousel";
import Bookus from "../bookus";
import "./index.css";
const Multislider = (props) => {
  return (
    <div>
      <Carousel
        responsive={props.responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={1490}
        showDots={false }
        centerMode={props.centermode}
        arrows={true}
        partialVisible
      >
        {props.data.map((val) => (
          <Bookus img={val.img} heading={val.heading} 
          
          desc={val.description} />
        ))}
        
      </Carousel>
    </div>
  );
};

export default Multislider;
