let feturecard_description = {
  heading1: "E-BOOKING",
  
  description1:
    " Oceanmate provides an automated e-booking platform for the entire marine and shipping ecosystem including the ship owners, ship management companies, seafarers and mariners.",
  
    heading2: "ROUND-THE-CLOCK CUSTOMER SUPPORT CENTER",
  
    description2:
    "Get round-the-clock customer support from our travel coordinators with the Oceanmate automated e-booking platform for seamen tickets.",
 
    heading3: "AGILE SYSTEM",
  
    description3:
    "Instantly change travel schedules with visa assistance and receive customised MIS reports and updated invoices with the Oceanmate central dashboard.",
  
    heading4: "MULTIPLE OPTIONS WITH OCEANMATE",
  
    description4:
    "Get the best discounted air fares, apart from marine special discounted fares, GRP fares, and fixed departures as per inventory. You can create requests as per the requirements for GRP fares.",
};

export default feturecard_description;
