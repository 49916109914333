import React , { useState } from "react";
import Button from "../../components/button";
import FeatureCard from "../../components/featureCard";
import "./index.css";
import { BsWallet2, BsFastForward } from "react-icons/bs";
import { RxCounterClockwiseClock } from "react-icons/rx";
import diamond from "../../assests/images/icons/diamond.svg";
import agli from "../../assests/images/icons/agli.svg";
import SEAFARERSTRAV from "../../assests/images/icons/SEAFARERS TRAVEL & VISA.svg";
import hour from "../../assests/images/icons/hour.svg";
import feturecard_description from "../../assests/data/feturecard";
import seafarers from "../../assests/data/seafarers";
import Seafarerscard from "../../components/seafearers";
import bamboo from "../../assests/images/icons/bamboo.svg";
import worker from "../../assests/images/icons/workers.svg";
import sea from "../../assests/images/background/shipbanner.jpg";
import girl1 from "../../assests/images/background/girl1.jpg";
import girl2 from "../../assests/images/background/girl2.jpg";
import girl3 from "../../assests/images/background/girl3.jpg";
import girl4 from "../../assests/images/background/girl4.jpg";
import Multislider from "../../components/multicarousel";
import data from "../../assests/data/data";
import Team from "../../components/team";
import team1 from "../../assests/images/team/AKSHAY_ANKIT_CEO_OCEANMATE.jpg";
import team2 from "../../assests/images/team/SWATI_RANJAN_DIRECTOR_OCEANMATE.jpg";
import team3 from "../../assests/images/team/NILESH_KUMAR_DIRECTOR_OCEANMATE.jpg";
import Accordion from "../../components/accordion";
import accordion from "../../assests/data/accordion";
import video from "../../assests/images/video/oceanmate_banner02.mp4";
import AOS from "aos";

import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
const Home = () => {
  AOS.init({ able: "mobile" });
  const hotelresponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const bookusresponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const testimonialsresponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };


  return (
    <div className="container-fluid p-0">
      {/* <Player playsInline autoPlay 
    src={video}
    /> */}
      <video
        autoPlay
        width="100%"
        height="10%"
        playsInline
        loop
        muted
        style={{ pointerEvents: "none" }}
      >
        <source src={video} />
      </video>
      {/* <div className="bg_home">
        <div className="container">
          <div className="row">
            <div className="col-sm-7 text-white">
              <h1 className=" f-45">
                Navigate the seas with ease: smart solutions for your marine
                travels
              </h1>
              <p>
                Smooth sailing around the world, with hassle-free crew travel
                management.
              </p>
              <Button
                bg="#e77000"
                color="#ffffff"
                border="1px solid #e77000"
                padding="15px"
                fs="15px"
                fw="600"
                textDecoration="underline"
                className="custom_btn"
                transition="all 0.5s"
              >
                KNOW MORE
              </Button>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container-fluid p-0 mt-5 bg-white">
        <div className="row container-fluid p-0">
          <div className="col-sm-12 text-center my-3 ">
            <p className=" our text-primary ls-base fw-semibold f-16 ">
              OUR SERVICES
            </p>
            <h1 className=" unm f-22 fw-bold ls-base text-secondary">
              UNMATCHED ATTRIBUTES
            </h1>
          </div>
        </div>
        <div
          className="container-fluid"
          data-aos="fade-up"
          data-aos-offset="150"
          data-aos-delay="20"
          data-aos-duration="800"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top-center"
        >
          <div className="row mt-5  p-0 ">
            <div className="col-sm-1"></div>
            <div className="col-sm-5 mt-1">
              <FeatureCard
                icon={BsWallet2}
                heading={feturecard_description.heading1}
                desc={feturecard_description.description1}
                height="290px"
                className="wall feature__card"
              />
            </div>
            <div className="col-sm-5 mt-1">
              <FeatureCard
                icon={RxCounterClockwiseClock}
                icocclassName="rotateicon"
                heading={feturecard_description.heading2}
                desc={feturecard_description.description2}
                height="290px"
              />
            </div>
            <div className="col-sm-1"></div>
          </div>
          <div className="row  p-0 ">
            <div className="col-sm-1"></div>
            <div className="col-sm-5 mt-4">
              <FeatureCard
                className=""
                img={agli}
                icocclassName="rotateicon"
                heading={feturecard_description.heading3}
                desc={feturecard_description.description3}
                height="290px"
              />
            </div>
            <div className="col-sm-5 mt-4">
              <FeatureCard
                img={diamond}
                icocclassName="rotateicon"
                heading={feturecard_description.heading4}
                desc={feturecard_description.description4}
                height="290px"
              />
            </div>
            <div className="mt-4"></div>

            <div className="col-sm-1"></div>
          </div>
        </div>
        <div className="container-fluid  p-0  ">
          <div className="bg_men">
            <div
              className="container-fluid"
              data-aos="fade-up"
              data-aos-offset="150"
              data-aos-delay="20"
              data-aos-duration="800"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-anchor-placement="top-center"
            >
              <div className="row mt-5   ">
                <div className="col-sm-1"></div>
                <div className="col-sm-5  mt-4 ">
                  <Seafarerscard
                    heading={seafarers.heading1}
                    img={SEAFARERSTRAV}
                    desc={seafarers.description1}
                    height="305px"
                    className=" hour feature__card bg-white mt-5 "
                  />
                </div>
                <div className=" col-sm-5  mt-4">
                  <Seafarerscard
                    heading={seafarers.heading2}
                    img={hour}
                    desc={seafarers.description2}
                    height="305px"
                    className=" feature__card  bg-white  mt-5"
                  />
                  {""}
                </div>

                <div className="col-sm-1"></div>
              </div>
              <div className="row mb-5 ">
                <div className="col-sm-1"></div>
                <div className="col-sm-5 " d>
                  <Seafarerscard
                    heading={seafarers.heading3}
                    img={bamboo}
                    desc={seafarers.description3}
                    height="305px"
                    className="feature__card bg-white  mt-4 text justify center"
                  />
                </div>
                <div className="col-sm-5 ">
                  <Seafarerscard
                    heading={seafarers.heading5}
                    img={worker}
                    desc={seafarers.description5}
                    height="305px"
                    className="feature__card bg-white mt-4 text-justify"
                  />
                  <div className="mt-3"></div>
                </div>
              </div>
            </div>

            <div className=" popular_section ">
              <div className="row container-fluid">
                <div className="col-sm-12 text-center mt-5 py-5">
                  <p className="text-primary ls-6 fw-semibold f-16 ">
                    WHY OCEANMATE
                  </p>
                  <h1 className="text-uppercase fw-semibold text-secondary">
                    Trusted companion on the high seas{" "}
                  </h1>
                  <div className="container-fluid p-0">
                    <div className="row">
                      <div className="col-sm-10 m-auto">
                        <img
                          src={sea}
                          className="rounded_image my-5"
                          data-aos="fade-up"
                          data-aos-offset="200"
                          data-aos-delay="20"
                          data-aos-duration="800"
                          data-aos-easing="ease-in-out"
                          data-aos-mirror="true"
                          data-aos-once="true"
                          data-aos-anchor-placement="top-center"
                          width="60%"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="book container my-3 ">
                <Multislider
                  data={data.bookus}
                  centermode={false}
                  responsive={bookusresponsive}
                />
              </div>
            </div>
            <div className="mt-5"></div>
          </div>
        </div>

        <div className="contactus_parallax ">
          <h2 className="unlock mt-5 text-white mb-5 f-35 text-center">
            UNLOCK UNBEATABLE SAVINGS ON FLIGHTS AND HOTELS
          </h2>
          <div className=" container">
            <div className="row">
              {data.hotel.map((val) => (
                <div className="hotell col-sm-3">
                  <img src={val.img} width="80%" />
                </div>
              ))}
            </div>
            {/* <Carousel
              responsive={hotelresponsive}
              containerClass="carousel-container"
              autoPlay={true}
              autoPlaySpeed={1000}
              infinite={true}
            >
              {data.hotel.map((val) => (
                <div>
                  <img src={val.img} width="50%" className="m-auto" />
                </div>
              ))}
            </Carousel> */}
          </div>
       
            <div className=" row text-center my-5">
              <Link className="text-decoration-none " to={"/contact"}>
                <Button
                  textTransform="uppercase"
                  padding="12px"
                  fw="500"
                  border="1px solid  #e77000"
                  bg=" #e77000"
                  color="#ffffff"
                  transition="all 0.5s"
                  className=" "
                >
                  Contact Us
                </Button>
              </Link>
            </div>
          
          <div className="mt-4"></div>
        </div>
        <div className=" container-fluid   ">
          <div className="row">
            <div className="col-sm-5 p-5 justify-content">
            
                <p className="text-primary f-22 ls-base fw-semibold text-justify">
                  UNPARALLEL BENEFITS
                </p>
                <h1 className="text-secondary f-20 text-uppercase text-start">
                  Unlock new horizons with our exclusive corporate travel
                  partnerships.
                </h1>
                <p className="text-justify mt-2 ">
                With modern technology and round-the-clock support,
            Oceanmate provides cost-effective solutions with an automated travel management portal. We
            welcome partnerships and maritime travel possibilities for businesses. Shipping and marine
            businesses can trust us for all of their maritime and offshore travel needs across the globe.
                </p>
                
                  <Link className="text-decoration-none" to={"/contact"}>
                    <Button
                      textTransform="uppercase"
                      padding="12px"
                      fw="500"
                      border="1px solid  #e77000"
                      bg=" #e77000"
                      color="#ffffff"
                      transition="all 0.5s"
                      className="custom_btn "
                    >
                      LEARN MORE
                    </Button>
                  </Link>
                

                      </div>
              
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-6 girl1">
                  <img
                    src={girl1}
                    width="100%"
                    style={{ marginTop: "-2rem" }}
                    
                  />
                </div>
                <div className="col-sm-6 girl2">
                  <img
                    src={girl2}
                    width="100%"
                    style={{ marginTop: "-2rem" }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 girl3">
                  <img
                    src={girl3}
                    width="100%"
                    style={{ marginTop: "-189px" }}
                  />
                </div>
                <div className="col-sm-6 girl4">
                  <img src={girl4} width="100%"
                   style={{ marginTop: "24px" }} 
                   />
                </div>
              </div>
            </div>
                    </div>
        </div>
        <div className="container mt-7">
          <div className="row p-5">
            <div className="col-sm-6 meet">
              <h6 className="amaz text-primary ls-base lh-base  fw-semibold text-uppercase f-20">
              MEET THE VISIONARY
              </h6>
              <h4 className=" text-secondary text-uppercase text-justify">
              MEMBER OF OCEANMATE
              </h4>
              <p className=" text-justify">
              It takes years to visualise a visionary idea and then a few more to lay the foundation stones. And the
              same happened for Mr. Akshay Ankit, CEO and one of the founding members of Oceanmate. Being a
            seafarer, he understood the gap and challenges in the marine ticketing system and got motivated to
            try something new for the shipping ecosystem. From searching for the right team members to
            brainstorming ideas for Oceanmate, everything was curated by Mr. Akshay.
              </p>
            </div>
            <div className="col-sm-2"></div>
            <div className="col-sm-3 team1">
              <Team img={team1} name={"Akshay Ankit"} designation={"CEO"} />
            </div>
            {/* <div className="col-sm-3 team2">
              <Team
                img={team2}
                name={"Swati Ranjan"}
                designation={"Director"}
              />
            </div>
            <div className="col-sm-3">
              <Team
                img={team3}
                name={"Nilesh Kumar"}
                designation={"Director"}
              />
            </div> */}
          </div>
        </div>
        <div
          className="parallax_feature"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="container p-0 my-5">
            <div className="row">
              <div className="col-sm-12 text-center my-3">
                <h6 className="text-primary fw-semibold ls-base lh-base text-uppercase ">
                  TESTIMONIALS
                </h6>
                <h1 className="text-uppercase text-secondary text-uppercase ">
                  What our clients say
                </h1>
              </div>
            </div>
            <Multislider
              data={data.testimonials}
              headingalign="left"
              descalign="justify"
              centermode={false}
              responsive={testimonialsresponsive}
              className={"text-justify,"}
            />
            <div className="text-center my-4 text-justify">
              <Link className="text-decoration-none" to={"/testimonials"}>
                <Button
                  textTransform="uppercase"
                  padding="12px"
                  fw="500"
                  border="1px solid  #e77000"
                  bg=" #e77000"
                  color="#ffffff"
                  transition="all 0.5s"
                  className="custom_btn "
                >
                  READ MORE
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="container-fluid ">
          <h6 className="text-primary text-center mb-4 ls-base text-uppercase ">
            Looking for answers
          </h6>
          <h2 className="text-center text-secondary mb-5 text-uppercase">
            Frequently Asked Questions
          </h2>
          <div className="container border-bottom">
            <div className="row m-auto">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12 mt-3">
                    <Accordion
                      heading={accordion.question7}
                      desc={accordion.answer7}
                      className="mb-4"
                    />
                    <Accordion
                      heading={accordion.question3}
                      desc={accordion.answer3}
                      className="mb-4"
                    />
                    <Accordion
                      heading={accordion.question5}
                      desc={accordion.answer5}
                      className="mb-4"
                    />
                  </div>
                  <div className="col-sm-12">
                    <Accordion
                      heading={accordion.question2}
                      desc={accordion.answer2}
                      className="mb-4"
                    />
                    <Accordion
                      heading={accordion.question4}
                      desc={accordion.answer4}
                      className="mb-4"
                    />
                    <Accordion
                      heading={accordion.question6}
                      desc={accordion.answer6}
                      className="mb-4"
                    />
                    <Accordion
                      heading={accordion.question1}
                      desc={accordion.answer1}
                      className="mb-5"
                    />
                  </div>
                  <div className="mb-5"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="contactus_parallax3 py-5 p-0 "
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="container ">
            <div className="row mb-2">
              <div className="col-sm-7 ">
                <h1 className="f-35 text-white text-capitalize  ">
                  Innovative and automated maritime travel solution platform
                </h1>
              </div>

              <Link className="text-decoration-none mt-3" to={"/contact"}>
                <Button
                  textTransform="uppercase"
                  padding="12px"
                  fw="700"
                  border="1px solid  #e77000"
                  bg=" #e77000"
                  color="#ffffff"
                  transition="all 0.5s"
                  className="custom_btn "
                >
                  CONTACT US
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Home;
