let about = {
  description1:
    "Oceanmate, a division of Traveego Marine Group, is your most trustworthy trip companion for the shipping and related industries ecosystem. We are a provider of complete sea travel solutions to shipping firms, port agents, and ship owners. By offering a one-stop shop for all of the needs of seafarers, we hope to stream line the travel process for our clients.",

  description2:
     " Established in 2021 with a vision to make your crew exchange process smooth, hassle free and in an approved budget. Our core team is made up of current and former seafarers who have a thorough understanding of the challenges facing the marine industry and are constantly striving to offer solutions that are specifically tailored to those challenges. We endeavour to offer our clients the finest and customised solutions. Our team of seasoned travel professionals has an in-depth understanding of the shipping sector, and we work closely with our clients to understand their specific needs so that we can personalise our solutions. We provide 24/7 service with global operation, and we want to make sure that our clients can get in touch with us at any time.",

  description3:
    "Oceanmate has developed an automated marine travel platform to manage online ticketing systems to provide seaman tickets, corporate tickets, pre-purchased tickets, LCC fares, visa assistance,hotel accommodations, port clearance and transfer, and many more. We are the first in India and Asia tooffer B2C online marine fare services. These solutions set us apart from traditional services provided in the marine industry.",

  description4:
    " We take pride in our ability to provide cost-effective solutions without compromising on the quality of service. Our aim is to help our clients save time and money, so they can focus on their core business operations.",

  subheading1: "OUR MISSION",
  description5:
    " Our mission is to provide an efficient and user-friendly online ticketing system for the marine industry, designed to simplify and stream line the process of booking and managing tickets for crew and seafarers. Our platform will offer a seamless and secure experience for both customers and marine operators, ensuring reliable and timely access to vital information such as schedules, pricing, and availability. We are committed to leveraging the latest technology to continuously improve our system, with a focus on enhancing convenience,transparency, and customer satisfaction. Ultimately, our goal is to become the preferred ticketing partner for marine operators and travellers alike, enabling seamless and hassle-free journeys across the seas.",

  subheading2: "OUR VISION",
  description6:
    "Our vision is to create a seamless and user-friendly online ticketing system for the marine industry that enables passengers to easily purchase tickets, access important information, and manage their bookings. Through our system, we aim to simplify the booking process for passengers and improve operational efficiency for marine operators, leading to enhanced customer satisfaction and increased revenue. We strive to provide a secure and reliable platform that offers a range of payment options and enables passengers to easily modify or cancel their bookings as needed. Our ultimate goal is to become the go-to online ticketing platform for the marine industry, delivering a seamless and stress-free travel experience for all passengers.",

  heading3: "Understanding Oceanmate",
  subheading3:
    "We are your trustworthy travel companion for the shipping ecosystem",

  subheading4: " Team of oceanmate",
  description7:
    "Oceanmate is comprised of passionate leaders and dedicated professionals, providing excellent travel management services to all sectors of the marine industry. Meet the management team of Oceanmate, which is dedicated to addressing the challenges in the marine and shipping industries .",
  cardheading1: "On-the go Schedule Change",
  carddescription1:
    "Oceanmate understands that last-minute schedule changes are inevitable in the marine and shipping industries. We are with you on these changes.",
  
    cardheading2:"Round-the-clock assistance and guidance",
  carddescription2:
    "The Oceanmate team is always available for your assistance and to guide you with the training you need to understand and take control of your service choices.",
  cardheading3: "Readily available customized invoices",
  carddescription3:
    "At Oceanmate, get your invoices along with the customization requested for. With few clicks, you can access and check your invoices",
    
    cardheading4: "ONLINE PORTAL FOR SEAMEN FARE",
  carddescription4: "Oceanmate provides online portal for seamen fare that allows seafarers to book travel arrangements including flights and other transportation options, as well as accommodation and other related services."
};

export default about;
