import React from "react";
import "./index.css";
import Testimonialcard from "../../components/testimonial_Card";
import team4 from "../../assests/images/background/team4.jpg";
import team6 from "../../assests/images/background/team6.jpg";
import team7 from "../../assests/images/background/team7.jpg";
import team11 from "../../assests/images/background/team11.jpg";
import team12 from "../../assests/images/background/team12.jpg";
import team13 from "../../assests/images/background/team13.jpg";

import Button from "../../components/button";
import AOS from "aos";

const Testimonial = () => {
  AOS.init({ able: "mobile" });
  return (
    <div>
      <div className="bg_testi">
        <div>
          <h2 className="test f-35 text-uppercase text-primary text-center ls-6">
            Testimonials
          </h2>
          <h2 className="real text-white text-uppercase  f-40">
            Real stories from real people
          </h2>
        </div>
      </div>
      <div className="col-sm-12 text-center mt-5">
        <p className="text-secondary ls-base fw-bold f-30">
          A word of appreciation from our clients
        </p>
      </div>

      <div className="container my-5">
        <div className="row">
          <div
            className="col-sm-4"
            data-aos="fade-right"
            data-aos-offset="150"
            data-aos-delay="20"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
          >
            <div className="testimonials  mt-2 p-3">
              <div className="d-flex align-items-center justify-content-center">
                <img
                  src={team13}
                  width="
      100px"
                  height="100px"
                  className="image "
                />
              </div>
              <h1 className=" f-15 text-center mt-4 text-primary">Gaurav</h1>

              <p className="my-4 text-center ">
                Oceanmate provides an automated e-booking platform for marine
                travel tickets in a convenient and efficient way for seafarers
                to book their travel and can help streamline the process of
                marine travel.
              </p>
            </div>
          </div>
          <div
            className="col-sm-4"
            data-aos="fade-right"
            data-aos-offset="150"
            data-aos-delay="20"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
          >
            {" "}
            <div className="testimonials  mt-2 p-3">
              <div className="d-flex align-items-center justify-content-center">
                <img
                  src={team4}
                  width="
      100px"
                  height="100px"
                  className="image "
                />
              </div>
              <h1 className=" f-15 text-center mt-4 text-primary">Amir</h1>

              <p className="my-4 text-center ">
                With Oceanmate, we managed to make a huge transfer very
                smoothly, and at an affordable rate as well. With just a few
                clicks we got everything we wanted from the convenience of our
                cabin.
              </p>
            </div>
          </div>
          <div
            className="col-sm-4"
            data-aos="fade-right"
            data-aos-offset="150"
            data-aos-delay="20"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
          >
            {" "}
            <div className="testimonials  mt-2 p-3">
              <div className="d-flex align-items-center justify-content-center">
                <img
                  src={team12}
                  width="
      100px"
                  height="100px"
                  className="image "
                />
              </div>
              <h1 className=" f-15 text-center mt-4 text-primary">Sanjay</h1>

              <p className="my-4 text-center ">
                I can't give enough appreciation for the services I have
                received from Oceanmate. Everything went smoothly with very
                little for me to do. Thank you Oceamate for your support.
              </p>
            </div>
          </div>
        </div>
        <div className="row my-4">
          <div
            className="col-sm-4"
            data-aos="fade-left"
            data-aos-offset="150"
            data-aos-delay="20"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
          >
            <div className="testimonials  mt-2 p-3">
              <div className="d-flex align-items-center justify-content-center">
                <img
                  src={team11}
                  width="
      100px"
                  height="100px"
                  className="image "
                />
              </div>
              <h1 className=" f-15 text-center mt-4 text-primary">Ajay</h1>

              <p className="my-4 text-center ">
                Oceanmate provides a truly amazing online ticketing platform,
                which not only reduced the paperwork but also sped up the
                processing of ticket sales and improved customer service.
              </p>
            </div>
          </div>
          <div
            className="col-sm-4"
            data-aos="fade-left"
            data-aos-offset="150"
            data-aos-delay="20"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
          >
            {" "}
            <div className="testimonials  mt-2 p-3">
              <div className="d-flex align-items-center justify-content-center">
                <img
                  src={team7}
                  width="
      100px"
                  height="100px"
                  className="image "
                />
              </div>
              <h1 className=" f-15 text-center mt-4 text-primary"> Niraj</h1>

              <p className="my-4 text-center ">
                I ought to say, Oceanmate is outstanding at what it does
                particularly at last-minute changes. I booked tickets outside of
                normal business hours and wanted to make some changes one day
                before the flight, and everything couldn't be easier.
              </p>
            </div>
          </div>
          <div
            className="col-sm-4"
            data-aos="fade-left"
            data-aos-offset="150"
            data-aos-delay="20"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
          >
            {" "}
            <div className="testimonials  mt-2 p-3">
              <div className="d-flex align-items-center justify-content-center">
                <img
                  src={team6}
                  width="
      100px"
                  height="100px"
                  className="image "
                />
              </div>
              <h1 className=" f-15 text-center mt-4 text-primary">Ahmed</h1>
              <p className="my-4 text-center">
                I must say that Oceanmate is such a relief when it comes to
                booking marine tickets. A smooth and user-friendly platform for
                all the mariners and shipping ecosystem.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="contactus_parallax3"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="container ">
          <div className="row mb-2">
            <div className="col-sm-6 ">
              <h1 className="f-35 text-white text-capitalize text-start">
                Efficiently navigating the seas with automated precision
              </h1>
            </div>

            <Button
              textTransform="uppercase"
              padding="12px"
              fw="700"
              border="1px solid  #e77000"
              bg=" #e77000"
              color="#ffffff"
              transition="all 0.5s"
              className="custom_btn  mt-3 "
            >
              CONTACT US
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
