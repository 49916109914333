import React from "react";
import "./index.css";
import Button from "../../components/button";
import seafarers from "../../assests/data/seafarers";
import Seafarerscard from "../../components/seafearers";
import SEAFARERSTRAV from "../../assests/images/icons/SEAFARERS TRAVEL & VISA.svg";
import bamboo from "../../assests/images/icons/bamboo.svg";

import worker from "../../assests/images/icons/workers.svg";

import hour from "../../assests/images/icons/hour.svg";
import bg_meen from "../../assests/images/background/seafarers.jpg";
import conve from "../../assests/images/background/CONVENIENCE.jpg"
import prom from "../../assests/images/background/PROMPT ACTION.jpg"
import real from "../../assests/images/background/REAL-TIME VISA RULES.jpg"
import save from "../../assests/images/background/TIME-SAVING.jpg"
import incr from "../../assests/images/background/INCREASED EFFICIENCY.jpg"
import Oceanmatecard from "../../components/oceanmatecard";
import card from "../../assests/data/card";
import girl3 from "../../assests/images/background/girl3.jpg";
import video from "../../assests/images/video/oceanmate_banner02.mp4";
import Aos from "aos";
import { Link } from "react-router-dom";
// import feturecard_description from "../../assests/data/feturecard";
// import sea from "../../assests/images/background/sea.jpeg";
// import blog from "../../assests/images/background/blog.jpg";
// import Multislider from "../../components/multicarousel";
// import data from "../../assests/data/data";
const WhyOceanmate = () => {
  const bookusresponsive = {};
  Aos.init();
  return (
    <div className="container-fluid p-0">
      <video autoPlay muted loop width="100%">
        <source src={video} />
      </video>
      <div className="aboutdesc container mt-5">
        <div className="row my-4">
          <div className="col-sm-12 m-auto">
          <div className="col-sm-12 text-center">
            <p className="text-primary ls-base fw-bold f-22">
            OCEANMATE MARINE TICKETING SYSTEM
            </p>
            <h1 className="f-20 fw-bold ls-base text-secondary text-uppercase">
            CHOOSE THE BEST
            </h1>
          </div>
            <p className="conatiner f-14 lh-base text-justify f-15 mt-4 ">{card.para1}</p>
            <p className="container text-primary  fw-bold f-22  lh-base text-center text-uppercase mt-5">
              {card.para2}
            </p>
           
            <div  className="conatiner text-justify f-15">
            <p>{card.para3}</p>
            <p>{card.para4}</p>
            <p>{card.para5}</p>
            <p>{card.para6}</p>
            <p>{card.para7}</p>
            <p>{card.para8}</p>
            <p>{card.para9}</p></div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row p-0">
          <div className="col-sm-12 text-center mt-5">
            <p className="text-primary ls-base fw-bold f-18">
              WE MEET THE NEEDS OF
            </p>
            <h1 className="f-22 fw-bold ls-base text-secondary text-uppercase">
              Seafarers mastering the seas
            </h1>
          </div>
        </div>
      </div>
      <div className=" container-fluid p-0 ">
        <div className="bg_meen">
          <div
            className="container-fluid"
            data-aos="fade-up"
            data-aos-offset="150"
            data-aos-delay="20"
            data-aos-duration="800"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
          >
            <div className="row mt-4   ">
              <div className="col-sm-1"></div>
              <div className="stra col-sm-5  mt-3 ">
                <Seafarerscard
                  heading={seafarers.heading1}
                  img={SEAFARERSTRAV}
                  desc={seafarers.description1}
                  height="305px"
                  className="strav feature__card bg-white mt-5"
                />
              </div>
              <div className="col-sm-5  mt-3">
                <Seafarerscard
                  heading={seafarers.heading2}
                  img={hour}
                  desc={seafarers.description2}
                  height="305px"
                  className="feature__card  bg-white  mt-5"
                />
                {""}
              </div>

              <div className="col-sm-1"></div>
            </div>
            <div className="row mb-5 ">
              <div className="col-sm-1"></div>
              <div className="col-sm-5 " d>
                <Seafarerscard
                  heading={seafarers.heading3}
                  img={bamboo}
                  desc={seafarers.description3}
                  height="305px"
                  className="feature__card bg-white  mt-4 text justify center"
                />
              </div>
              <div className="col-sm-5 ">
                <Seafarerscard
                  heading={seafarers.heading5}
                  img={worker}
                  desc={seafarers.description5}
                  height="305px"
                  className="feature__card bg-white mt-4 text-justify"
                />
              </div>
              <div className="mt-5"></div>
              <div className="mt-5"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid  ">
        <div className=" text-center ">
          <p className="text-primary ls-base fw-bold f-18  ">
            {card.sectionsubheading}
          </p>

          <h1 className="f-22 fw-bold ls-base text-secondary text-uppercase">{card.sectionheading}</h1>
        </div>

        <div className="container-fluid mt-5">
          <div className="row">
            <div
              className="col-sm-6 border_dashed"
              data-aos="fade-right"
              data-aos-offset="150"
              data-aos-delay="20"
              data-aos-duration="600"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-anchor-placement="top-center"
            >
              <Oceanmatecard
                heading={card.heading1}
                desc={card.description1}
                className="mb-2"
                img={conve}
              />
            </div>
            <div className="col-sm-6"></div>
          </div>

          <div className="row">
            <div
              className="col-sm-6 border_dashed"
              data-aos="fade-left"
              data-aos-offset="150"
              data-aos-delay="20"
              data-aos-duration="600"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-anchor-placement="top-center"
            ></div>
            <div
              className="col-sm-6 mt-40"
              data-aos="fade-left"
              data-aos-offset="150"
              data-aos-delay="20"
              data-aos-duration="600"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-anchor-placement="top-center"
            >
              <Oceanmatecard
                heading={card.heading2}
                desc={card.description2}
                className="mb-2"
                img={real}
              />
            </div>
          </div>

          <div className="row">
            <div
              className="col-sm-6 border_dashed"
              data-aos="fade-right"
              data-aos-offset="150"
              data-aos-delay="20"
              data-aos-duration="600"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-anchor-placement="top-center"
            >
              <Oceanmatecard
                heading={card.heading3}
                desc={card.description3}
                className="mb-2"
                img={prom}
              />
            </div>
            <div className="col-sm-6"></div>
          </div>

          <div className="row">
            <div className="col-sm-6 border_dashed"
            data-aos="fade-left"
            data-aos-offset="150"
            data-aos-delay="20"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-center" ></div>
            <div className="col-sm-6 mt-40
            "
            data-aos="fade-left"
            data-aos-offset="150"
            data-aos-delay="20"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-center">
              <Oceanmatecard
                heading={card.heading4}
                desc={card.description4}
                className="mb-2"
                img={save}
              />
            </div>
          </div>

          <div className="row">
            <div
              className="col-sm-6 border_dashed"
              data-aos="fade-right"
              data-aos-offset="150"
              data-aos-delay="20"
              data-aos-duration="600"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-anchor-placement="top-center"
            >
              <Oceanmatecard
                heading={card.heading5}
                desc={card.description5}
                className="mb-2"
                img={incr}
              />
            </div>
          </div>
          <div className="col-sm-6"
           data-aos="fade-left"
           data-aos-offset="150"
           data-aos-delay="20"
           data-aos-duration="600"
           data-aos-easing="ease-in-out"
           data-aos-mirror="true"
           data-aos-once="true"
           data-aos-anchor-placement="top-center">

          </div>
        </div>
      </div>

      <div
        className="contactus_parallax3"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="container ">
          <div className="row mb-2">
            <div className="col-sm-7 ">
              <h1 className="f-35 text-white text-capitalize ">
                Navigate the future with seamless marine travel{" "}
              </h1>
            </div>

            <Button
              textTransform="uppercase"
              padding="12px"
              fw="700"
              border="1px solid  #e77000"
              bg=" #e77000"
              color="#ffffff"
              transition="all 0.5s"
              className="custom_btn  mt-3 "
            >
              READ MORE
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyOceanmate;
