import React from "react";
import "./index.css";
import Button from "../button";

const FeatureCard = (props) => {
  return (
    <div>
      <div className={`featurecard p-4 + ${props.className}` } style={{ height: props.height }}>
        <div>
          {props.icon ? (
            <props.icon
              color="#e77000"
              size={28}
              className={props.iconclassName}
            />
          ) : (
            <img src={props.img} />
          )}
          <h4 className="text-uppercase fw-semibold my-2">{props.heading}</h4>
          <p className="text-justify my-1">{props.desc}</p>
         
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
