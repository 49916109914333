import React from "react";
import image from "../../assests/images/background/girl1.jpg";
const Oceanmatecard = (props) => {
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-7">
            <h4 className="text-secondary text-semibold text-uppercase">
              {props.heading}
            </h4>
            <p className="text-justify">{props.desc}</p>
          </div>
          <div className="col-sm-5">
            <img src={props.img} width="100%" height="100%" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Oceanmatecard;
