import React from "react";

const List = (props) => {
  return (
    <div>
      <p>{props.icon || ""}</p>
      <h2 className="f-17 text-primary text-uppercase mt-5 mb-3">
        {props.heading || ""}
      </h2>
      <ul className="list-unstyled">
        {props.data.map((val) => (
          <li
            style={{ color: props.color, textAlign: props.textAlign }}
            className="text-capitalize f-13 mt-3 "
          >
            {val.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default List;
