import React from "react";
import "./index.css";
import team from "../../assests/images/background/team1.jpg";
const Team = (props) => {
  return (
    <div>
      <div class="content">
        <div class="content-overlay"></div>
        <img class="content-image" src={props.img} alt="" />
        <div class="content-details fadeIn-bottom">
          <p class="text-white pointer text-uppercase" onClick={props.onClick}>
            {props.name}
          </p>
          <h4 class="text-alternative">{props.designation}</h4>
        </div>
      </div>
    </div>
  );
};

export default Team;
