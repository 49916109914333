import React from "react";
import "./index.css";

const Bookus = (props) => {
  return (
    <div className="my-5 m-auto">
      <div className="bookus_card p-3">
        <div className="row">
          <div className="img col-sm-2">
            <img
              src={props.img || ""}
              width={props.width}
              height={props.height}
            />
          </div>
          <div className="col-sm-7">
       
            <h4
              className="fw-semibold f-17 text-center"
              style={{ textAlign: props.headingalign || "" }}
            >
              {props.heading || ""}
            </h4>
          </div>
        </div>
        <div className="row my-2">
          <div className="col-sm-12 text-center">
            <p style={{ textAlign: props.descalign }}>{props.desc || ""}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bookus;
