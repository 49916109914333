import React from "react";
import "./index.css";
import Input from "../../components/input";
import Button from "../../components/button";

const Contact = () => {
  return (
    <div className="conatiner-fluid p-0">
      <div className="bg_contact">
        <div>
          <p className="getin text-primary text-center f-35 ls-base fw-semibold mb-0">
            GET IN TOUCH
          </p>
          <h1 className="lets text-white f-40">LET’S CONNECT</h1>
        </div>
      </div>

      


<div className="container">
    <div className="col-sm-12 mt-5">
    <h1 className="f-45 fw-bold text-primary container-fluid mt-2 pt-4 text-center">
                SAY HELLO TO US
              </h1>
              <p className="f-18 text-justify mt-4 mx-2 text-center text-secondary">
                Streamline your marine travel with Oceanmate automated marine
                travel and management solutions.
              </p>
      </div>
  




      <div className="row p-0  mt-5">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 p-4 rounded_card   mt-5">
          <p className="f-22 text-primary fw-semibold mt-5 ">ADDRESS</p>
          <p className="lh-base text-secondary ">
            3WS5D, 3rd Floor, West Block Mani Casadona, Plot No. IIF/04, Action
            Area- IIF, Newtown, Kolkata, West Bengal, 700156
          </p>
        </div>

   <div className="col-sm-1"></div>
        <div className="col-sm-4 p-4 rounded_card mt-5 ">
          <p className="f-22 text-primary fw-semibold mt-5">CONTACT US</p>
          <a
            href="mailto:connect@oceanmate.in"
            className="text-decoration-none inherit text-secondary"
          >
            Email : connect@oceanmate.in
          </a>
          <div>
            <a
              className="text-decoration-none inherit text-secondary"
              href="tel:+91 9903835339"
            >
              Mobile : +919903835339
            </a>
          </div>
        </div>
      
      </div> 
      </div>

      <div
        className="parallax_feature"
        style={{
          display: "grid",
          placeItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="container-fluid mt-7 p-3 rounded_card1 ">
          <p className="text-primary fw-bold ls-base f-20 text-center  ">
            REACH OUT TO US
          </p>
          <h1 className="container f-45 text-center text-secondary">SEND US A MESSAGE</h1>
          <div className="container-fluid p-4">
            <form>
              <div className="row">
                <div className="col-sm-6  mt-4">
                  <Input
                    type="text"
                    placeholder="First Name "
                    // className="custom_input"
                    border="1px solid grey"
                    padding="8px"
                    // rounded="5px"
                    width="100%"
                  />
                </div>
                <div className="col-sm-6 mt-4">
                  <Input
                    type="text"
                    placeholder="Last Name"
                    // className="custom_input"
                    border="1px solid grey"
                    padding="8px"
                    // rounded="5px"
                    width="100%"
                  />
                </div>
              </div>
              <div className="row my-4">
                <div className="col-sm-12">
                  <Input
                    type="email"
                    placeholder="Email"
                    width="100%"
                    // className="custom_input"
                    border="1px solid grey"
                    padding="8px"
                    // rounded="5px"
                  />
                </div>
              </div>
              <div className="row 5">
                <div className="col-sm-12">
                  <textarea
                    placeholder=" Message"
                    // className="custom_input"
                    rows={3}
                    style={{
                      width: "100%",
                      height: "20vh",
                      border: "1px solid grey",
                    }}
                  />
                </div>
              </div>
              <div className="text-center my-4">
                <Button
                  textTransform="uppercase"
                  padding="12px"
                  fw="500"
                  border="1px solid  #e77000"
                  bg=" #e77000"
                  color="#ffffff"
                  transition="all 0.5s"
                  className="custom_btn "
                  width="100%"
                >
                  
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className="mt-7"></div>
      </div>
    </div>
  );
};

export default Contact;
