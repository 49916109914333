import React, { useState } from "react";
import "./index.css";
import { TfiAngleDown, TfiAngleUp } from "react-icons/tfi";
const Accordion = (props) => {
  let [show, setShow] = useState(false);

  const showhandler = () => {
    setShow(!show);
  };
  return (
    <div className={props.className}>
      <div
        className={`${
          !show
            ? "accordion_heading pointer"
            : "accordion_heading pointer"
        }`}
        onClick={showhandler}
      >
        <h5 className="accordion_question  "> {props.heading}</h5>
        {!show ? <TfiAngleDown className="down" color="dark" /> : <TfiAngleUp className="down" color="dark" />}
      </div>
      <div
        // className={`${
        //   show ? "accordion_body" : "accordion_body border-bottom"
        // }`}
      >
        {show ? <p className="px-1 mt-2">  {props.desc}</p> : <div></div>}
      </div>
    </div>
  );
};

export default Accordion;
