import fare from "../images/icons/cash.svg";
import asia from "../images/icons/asia.svg";
import ship from "../images/icons/sea.svg";
import ebooking from "../images/icons/ebooking.svg";
import online from "../images/icons/online.svg";
import bookus from "./bookus";
import reschedule from "../images/icons/reschedule.svg";
import hotel1 from "../images/hotels/best-flight1.jpg";
import hotel2 from "../images/hotels/best-flight2.jpg";
import hotel3 from "../images/hotels/best-flight3.jpg";
import hotel4 from "../images/hotels/best-flight4.jpg";
import Input from "../../components/input/index";
import team1 from "../../assests/images/team/AKSHAY_ANKIT_CEO_OCEANMATE.jpg";
import team2 from "../../assests/images/team/SWATI_RANJAN_DIRECTOR_OCEANMATE.jpg";
import team3 from "../../assests/images/team/NILESH_KUMAR_DIRECTOR_OCEANMATE.jpg";
import team4 from "../../assests/images/team/PIYUSH_KUMAR.jpg";
import team6 from "../../assests/images/team/prashantgyan.jpg";
let data = {
  bookus: [
    {
      img: fare,
      heading: bookus.heading1,
      description: bookus.description1,
    },
    {
      img: asia,
      heading: bookus.heading2,
      description: bookus.description2,
    },
    {
      img: ship,
      heading: bookus.heading3,
      description: bookus.description3,
    },
    {
      img: ebooking,
      heading: bookus.heading4,
      description: bookus.description4,
    },
    {
      img: online,
      heading: bookus.heading5,
      description: bookus.description5,
    },
    {
      img: reschedule,
      heading: bookus.heading6,
      description: bookus.description6,
    },
  ],

  team: [
    {
      img: team1,
    },
    {
      img: team2,
    },
    {
      img: team3,
    },

    {
      img: team4,
    },

    {
      img: team6,
    },
  ],

  hotel: [
    {
      img: hotel1,
    },
    {
      img: hotel2,
    },
    {
      img: hotel3,
    },
    {
      img: hotel4,
    },
  ],
  footerlist: [
    {
      name: "3WS5D, 3rd Floor, West Block Mani Casadona, Plot No. IIF/04, Action Area- IIF, Newtown, Kolkata, West Bengal,700156",
    },
  ],
  footerlist1: [
    {
      name: "Home",
      url: "/",
    },
    {
      name: "About Us",
      url: "/about",
    },
    {
      name: "Why Oceanmate",
      url: "/ocean",
    },
    {
      name: "Eligibility",
      url: "/elgibility",
    },
    {
      name: "Testimonials",
      url: "/testimonials",
    },
    // {
    //   name: "Knowledge Centre",
    // },
    {
      name: "Contact Us  ",
      url: "/contact",
    },
  ],
  footerlist2: [
    {
      name: "Stay informed, stay connected - sign up for our newsletter today!",
    },
  ],
  testimonials: [
    {
      heading: "Gaurav",
      description:
        "I appreciate how Oceanmate has made the whole ticketing process hassle-free. Never going back to the complicated conventional method again. ",
    },
    {
      heading: "Amir",
      description:
        "With Oceanmate, we managed to make a huge transfer very smoothly, and at an affordable rate as well. With just a few clicks we got everything we wanted from the convenience of our cabin.",
    },
    {
      heading: " Sanjay",
      description:
        "I can't give enough appreciation for the services I have received from Oceanmate. Everything went smoothly with very little for me to do. Thank you Oceamate for your support.",
    },
    {
      heading: "Ajay",
      description:
        "Oceanmate provides a truly amazing online ticketing platform, which not only reduced the paperwork but also sped up the processing of ticket sales and improved customer service.  ",
    },
    {
      heading: "Niraj",
      description:
        "I tought to say, Oceanmate is outstanding at what it does particularly at last-minute changes. I was surprised that even in such short notice, I was able to make changes to my bookings.",
    },
  ],
  elgibilityslider: [
    {
      heading: "Passport",
      list1:
        "Have been issued within the previous 10 years, and the validity must not exceed 10 yrs.",
      list2:
        "At least have a validity of 3 months from the Date of Expiry of the visa.",
      list3: "Have at least 2 blank pages.",
    },
    {
      heading: "Continuous Discharge Certificate",
      list1: "Shall be valid for a period of ten years.",
    },
    {
      heading: "Covering Letter",
      list1: "Name and family name of the seafarer.",
      list2: "Name and flag of the boat",
    },
    {
      heading: "Letter of Guarantee",
      list1:
        "Any guarantee which has been obtained by means of misrepresentation made by the creditor, or with his knowledge and assent, concerning a material part of the transaction, is invalid.",
    },
    {
      heading: "DG Form 1",
      list1: "The information filled in the form must be authentic.",
    },
    {
      heading: "RT PCR Report",
      list1: "Negative RT PCR test report 48 hrs prior to departure.",
    },
    {
      heading: "OK To Board/Visa",
      list1:
        "Have been issued within the previous 10 years, and the validity must not exceed 10 yrs.",
    },
  ],
};

export default data;
