let card = {
  para1:
    "The marine ticketing is still offline and un-organized while the maritime sector is digitizing at a rate that has never been seen before. Floating queries among several travel agents and waiting for their quotes wastes crucial time, and various sources searching on the same routes have a substantial impact on pricing because airlines use a dynamic pricing policy based on searches made for specific routes. ",
  para2: "Does it ring a bell?",
  para3:
    "At Oceanmate, we have developed a solution that is specifically designed for the Maritime & associated Industry which serves as your one-stop travel solution, where your hunt for a trustworthy travel partner comes to an end. Maritime & associated industries are the sole focus for Oceanmate. ",
  para4:
    "With a few clicks on our online portal, your staff may book, hold, cancel, reschedule, or refund marine fares. As a result, it eliminates all time delays from consideration. The same site can be used for invoicing and billing. Our portal is built on the SBT (System Booking Tool) module, which channels decision-making while maintaining hierarchy (administrative control). ",
  para5:
  "Bookings can be made by a number of approved users, who each have a different level of authority. The online system can be made available to all regional and branch offices, enabling them to complete the crew's flight bookings while the centrally located administrative team can monitor the entire operation with a bird’s eye view.",
  para6:
    "As the marine sector is known for last-minute changes due to sudden, unwarranted situations. Just imagine that due to some unforeseen situation, there are changes in the scheduled movement of marine vessels.  The captain on-board can easily inform the port agency about these sudden changes and his plans to reschedule the travel tickets for the crew members for a new proposed date. Similarly, this scenario can occur when port agencies inform the captain about changes to the berthing prospects. Any such changes requiring revised travel arrangements can be booked and old booked schedules cancelled instantaneously by on-board staff without any loss of time thus avoiding price hikes",
  para7:
    "Any Such Changes done either by On-board staff or any of their branch offices will appear on the dashboard of the centrally located administrative team & will wait for their final approval. If no action taken these bookings will be auto cancelled prior they become non-refundable as per Airline policy.  ",
  para8:
    "Another factor that is addressed by the Online Ticketing Portal is monitoring the issued marine fares. The automated system will suggest (pop-up) options if any lower quote is available on the same flight or across combination of flights suitable as per travel plan. Thus, significantly reducing travel costs & creating a transparent relationship. ",
  para9:
    "All these aspects are easily manageable through the B2C Online Ticketing Portal, which is being offered by Oceanmate in India and across Asia. Overall, an online ticketing system in the marine industry offers numerous benefits over a conventional offline ticketing system, making it a preferable option for both seafarers and crew members and operators.",
  sectionsubheading: "MERITS OF AUTOMATED TICKETING SYSTEM",
  sectionheading: "NAVIGATING THE SEAS WITH TRUST AND RELIABILITY",

  heading1: "	Convenience",
  description1:
    "All suggested flight schedules will show current visa rules, which are essential for international travel.",

  heading2: "Real-time Visa Rules",
  description2:
    "At Oceanmate, we have developed a solution that is specifically designed for the Maritime & associated Industry which serves as your one-stop travel solution, where your hunt for a trustworthy travel partner comes to an end. Maritime & associated industries are the sole focus for Oceanmate.",

  heading3: "Prompt Action",
  description3:
    "Booking /holding /rescheduling /cancellation can be extended up to ship time delays, resulting in price increases or even preferable flights being sold out. Thus, significantly saving money and time for the organization.",

  heading4: "Time-saving",
  description4:
    "Online ticketing eliminates the waiting times that are common with offline ticketing systems. Offline system follows mailing system, where there is delay in reverting from the agency side",

  heading5: "Increased Efficiency",
  description5:
    "Online ticketing systems are more efficient than traditional systems, as they allow for faster processing of ticket sales and reduced paperwork. This results in fewer errors and improved customer service.",
  heading6: "24/7 Availability",
  description6:
    "Online ticketing systems are available 24/7, making it easy for seafarers and crew members to book tickets at any time, even outside of normal business hours.",
  heading7: "Cost-effective",
  description7:
    "The online booking system will eliminate the requirement for an experienced GDS staffer required for handling GDS bookings, and cheaper labour can be employed, thus significantly reducing operational costs at the clients' or shipping companies end.",
  heading8: "Real-time Information",
  description8:
    "Online ticketing systems can provide real-time information on ticket availability, schedules, and any changes or updates. This allows shipping companies to make informed decisions and plan their travel accordingly. Invoicing and billing are made easy and readily available at the client portal, and all invoices can be generated at any time.",
};

export default card;
