let bookus = {
  heading1: "BEST AIR FARES FOR MARINERS",
  description1:
    "Oceanmate uses the vast airline network to get the airfare that best matches your budget with quick booking options",
  heading2: "SERVICES ACROSS INDIA AND ENTIRE ASIA",
  description2:
    "Oceanmate is committed to offering crew members the finest travel options through automated ticketing system",
  heading3: "A TEAM OF SEAFARERS",
  description3:
    "We, as a team of mariners and seafarers, understand the different challenges of the marine and shipping industry",
  heading4: "HASSLE-FREE E-BOOKING OF TICKETS",
  description4:
    "The central dashboard is simple and straightforward to navigate. Choose services based on your requirements",
  heading5: "SECURE ONLINE PORTAL",
  description5:
    "Security-encrypted online portal for risk-free ticket booking. Oceanmate portal can be accessed easily with a few clicks",
  heading6: "TRANSPARENT SYSTEM",
  description6:
    "Easy cancellation to reschedule anytime-anywhere, live updates on schedules and weather information to other products",
};

export default bookus;
