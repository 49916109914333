import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assests/images/logo/oceanmatelogo.png";
import { TfiAngleDown } from "react-icons/tfi";
import { AiOutlineSearch, AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import "./index.css";
const Navbar = () => {
  let [show, setShow] = useState(false);
  const hidehandler = () => {
    setShow(false);
  };
  const showhandler = () => {
    setShow(true);
  };
  let links = [
    {
      name: "Home",
      url: "/",
    },
    {
      name: "About Us",
      url: "/about",
    },

    {
      name: "Why Oceanmate",
      url: "/ocean",
    },
    {
      name: "Eligibility",
      url: "/elgibility",
    },
    {
      name: "Testimonials",
      url: "/testimonials",
    },

    // {
    //   name: "Knowledge Center",
    //   url: "/knowledge",
    // },
    {
      name: "Contact Us",
      url: "/contact",
    },
  ];

  return (
    <div className="container-fluid p-0 ">
      <div className="navbar_main  p-2">
        <div className="logo ms-4 ">
          <NavLink to={"/"}>
            <img src={logo} width="100%" />
          </NavLink>
        </div>
        <div className="navbar_link">
          {links.map((val) => (
            <NavLink to={val.url} className="mx-3 link text-capitalize ls-1  ">
              {val.name}
            </NavLink>
          ))}
        </div>
        <div className="menubar" onClick={showhandler}>
          <AiOutlineMenu color="#ffffff" className="pointer" size={32} />
        </div>
        
      </div>
      {show ? (
        <div className="nav_bar">
          <div className="text-end ls-1">
            <AiOutlineMenu color="#222" onClick={hidehandler} />
          </div>
          {links.map((val) => (
            <NavLink to={val.url} className="links_mobile ">
              {val.name}
            </NavLink>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Navbar;
