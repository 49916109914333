import React from 'react'

const Seafarerscard = (props) => {
  return (

<div>
      <div className={`Seafarerscard p-5 + ${props.className}` } style={{ height: props.height }}>
        <div>
          {props.icon ? (
            <props.icon
              color="#e77000"
              size={18}
              className={props.iconclassName}
            />
          ) : (
            <img src={props.img} />
          )}
          <h4 className="text-uppercase fw-semibold my-2">{props.heading}</h4>
          <p className="des text-justify my-1">{props.desc}</p>
         
        </div>
      </div>
    </div>



    // <div className={props.className}>
    //   <div className="row">
    //     <div className="col-sm-2">
    //       <img src={props.img} />
    //     </div>
    //     <div className="col-sm-10">
    //       <h5 className="mb-3">{props.heading}</h5>
    //       <p>{props.desc}</p>
    //     </div>
    //   </div>
    // </div>
  )
}

export default Seafarerscard
