import React, { useState } from "react";
import "./index.css";
import captain from "../../assests/images/background/captain.jpg";
import ship1 from "../../assests/images/background/ship1.jpg";
import cater from "../../assests/images/background/cater.jpg";
import req from "../../assests/images/background/req.jpg";
import pump from "../../assests/images/background/pump.jpg"
import AOS from "aos";
import Button from "../../components/button";
import video from "../../assests/images/video/cruiseship.mp4";
import { Carousel } from "react-responsive-carousel";
import List from "../../components/list";
import data from "../../assests/data/data";

const Eligibility = () => {
  AOS.init({ able: "mobile" });
  let [showtravel, setShowtravel] = useState(false);

  const Showtravel = () => {
    setShowtravel(!showtravel);
  };
  return (
    <div className="container-fluid p-0">
      <div className="bg_elgli">
        <div className="text-white">
          <h5 className="eli text-center text-uppercase text-primary mt-5 f-35 ls-6">
            Eligibility
          </h5>
          <h2 className="f-40 elii text-uppercase">
            Smooth sailing from booking to boarding
          </h2>
        </div>
      </div>

      <p className=" we my-5  text-center text-secondary f-45 ls-6">
        WE OFFER SERVICES TO
      </p>
 
       <div className="container my-3">
        <div className=" row mt-7"
         data-aos="fade-right"
         data-aos-offset="150"
         data-aos-delay="20"
         data-aos-duration="500"
         data-aos-easing="ease-in-out"
         data-aos-mirror="true"
         data-aos-once="true"
         data-aos-anchor-placement="top-center">
          
          <div className=" caard col-sm-6   text-center">
            <img src={ship1} width="100%" height="100%" />
          </div>
          <div className="col-sm-6 ">
            <div className="row">
              <div className="col-sm-10 m-auto">
                <div className=" mt-5">
                  <p className="cruise text-primary fw-bold f-22 ls-6">
                    Cruise Line Crew
                  </p>
                  <p className="text-justify">
                    {" "}
                    Oceanmate’s automated ticketing platform is integrated with an SBT (System Booking Tool)
module. Oceanmate will help crew members of cruise ships travel with simplicity and at the lowest
available flight costs. Oceanmate offers a wide spectrum of affordable airfare for cruise line crew,
working onboard a passenger's vessel. Whether it's a one-way journey or a round trip, flights can be
booked, held, cancelled, rescheduled, or reimbursed at your convenience. Our online ticketing
system is available 24/7, making it easy for crew members to book tickets even outside of normal
business hours.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-7"
         data-aos="fade-left"
         data-aos-offset="150"
         data-aos-delay="20"
         data-aos-duration="500"
         data-aos-easing="ease-in-out"
         data-aos-mirror="true"
         data-aos-once="true"
         data-aos-anchor-placement="top-center">
          <div className="col-sm-6">
            <div className="col-sm-10 m-auto mt-5">
              <p
                className=" commer 
                  text-primary fw-bold f-22 ls-6 "
              >
                {" "}
                Commercial Crew Members
              </p>
              <p className="text-justify">
                Oceanmate has created a customized automated ticketing platform
                for Marine Air Travel Services while keeping in mind the
                structure of commercial ships and their providers. We provide
                all the travel options from the workplace to homes for all the
                commercial crew members. Oceanmate's commercial airfare is
                specifically designed to assist the commercial ship sector with
                a smooth transfer from their home to the port and the other way
                around. We understand that different crew members might have
                different requirements, therefore our commercial airfares are
                available in economy class, business class, and premium economy
                class, to accommodate everyone's needs.
              </p>
            </div>
          </div>
          <div className="caard col-sm-6  text-center  ">
            <img src={cater} width="100%" height="100%" />
          </div>
        </div>

        <div className="row mt-7"
         data-aos="fade-right"
         data-aos-offset="150"
         data-aos-delay="20"
         data-aos-duration="500"
         data-aos-easing="ease-in-out"
         data-aos-mirror="true"
         data-aos-once="true"
         data-aos-anchor-placement="top-center">
          <div className="caard req col-sm-6  text-center ">
            <img src={req} width="100%" height="100%" />
          </div>
          <div className="col-sm-6 ">
            <div className="row">
              <div className="col-sm-10 m-auto mt-5">
                <p className="crew text-primary fw-bold f-22 ls-6">
                  Crew Manager
                </p>
                <p className="text-justify">
                  We understand the necessity for crew managers and agents to
                  manage the team as a whole extremely effectively. Even crew
                  hiring is handled by them. To help the crewing agencies
                  deliver a cost-effective service, Oceanmate offers them
                  discounted flight ticket prices. At Oceanmate, crew managers
                  can book tickets for their entire crew with ticketing systems
                  that can provide real-time information on ticket availability,
                  schedules, and updates. Moreover, one will get updated
                  information on current visa rules, which will simplify the
                  work of a crew manager while booking tickets for a diverse
                  crew
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-7"
         data-aos="fade-left"
         data-aos-offset="150"
         data-aos-delay="20"
         data-aos-duration="500"
         data-aos-easing="ease-in-out"
         data-aos-mirror="true"
         data-aos-once="true"
         data-aos-anchor-placement="top-center">

          <div className="col-sm-6">
            <div className="col-sm-10 m-auto mt-5">
              <p className="off text-primary fw-bold f-22 ls-6 ">
                {" "}
                Offshore Workers
              </p>
              <p className="text-justify">
                To get their offshore workers to and from work,
many offshore marine companies need to provide the finest service possible. Oceanmate recognises the value of their comfort and provides them with the best and most efficient service in the marine
industry. Due to the offshore industry's reputation for last-minute changes, brought on by
unexpected scenarios, offshore workers are bound to make last-minute adjustments frequently.
There's no need to stress, though, as Oceanmate makes rescheduling and cancellations of bookings
convenient. In addition, online ticketing systems are available 24/7, which eliminates the waiting
time and speeds up the booking process.
              </p>
            </div>
          </div>
          <div className="caard pump col-sm-6  text-center ">
            <img src={pump} width="100%" height="100%" />
          </div>
        </div>

        <div className="row mt-7"
         data-aos="fade-right"
         data-aos-offset="150"
         data-aos-delay="20"
         data-aos-duration="500"
         data-aos-easing="ease-in-out"
         data-aos-mirror="true"
         data-aos-once="true"
         data-aos-anchor-placement="top-center">
          
          <div className="caard cap col-sm-6 text-center">
            <img src={captain} width="100%" height="100%" />
          </div>
          <div className="col-sm-6 ">
            <div className="row">
              <div className="col-sm-10 m-auto my-5">
                <p className="seaf text-primary fw-bold f-22 ls-6">
                  Seafarers Travel
                </p>
                <p className="text-justify ">
                With a dedicated team at Oceanmate and an plans, which is essential to the smooth operation of the shipping industry. Whether you're a
commercial crew member or an offshore worker, a visa is the most crucial document for everyone.
Oceanmate offers travel industry professionals with many years of expertise and solid knowledge of
visa regulations for various countries all across the globe. From proper documentation to swift and
hassle-free visa application processing, everything is taken care of at Oceanmate.
                </p>
              </div>
            </div>
          
          </div>
        </div>
      </div> 




      <div className="mt-7">
        <video autoPlay={true} loop muted playsInline width="100%">
          <source src={video} />
        </video>
      </div>
      <div className="elgi_documents p-4">
        {/* <div className="tab_travel_selection">
          <div className="d-flex align-items-center justify-content-between ">
            <span
              className={`${
                showtravel
                  ? "text-uppercase p-2 selected_tab pointer"
                  : "text-uppercase p-2 pointer"
              }`}
              onClick={Showtravel}
            >
              International
            </span>
            <span
              className={`${
                showtravel
                  ? "text-uppercase p-2 pointer "
                  : "text-uppercase p-2 selected_tab pointer"
              }`}
              onClick={Showtravel}
            >
              Domestic
            </span>
          </div>
        </div>
        {showtravel ? (
          <div className="container">
            <div className="row my-2 ">
              <div className="col-sm-4">1. Passport</div>
              <div className="col-sm-8">
                <ol type="a" className="text-justify">
                  <li>
                    Have been issued within the previous 10 years, and the
                    validity must not exceed 10 yrs.
                  </li>
                  <li>
                    At least have a validity of 3 months from the Date of Expiry
                    of the visa.
                  </li>
                  <li>Have at least 2 blank pages.</li>
                </ol>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-sm-4 ">
                2. Continuous Discharge Certificate
              </div>
              <div className="col-sm-8">
                <ol type="a">
                  <li>Shall be valid for a period of ten years.</li>
                </ol>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-sm-4">3. Covering Letter</div>
              <div className="col-sm-8">assdd</div>
            </div>
            <div className="row my-2">
              <div className="col-sm-4">4. Letter of Guarantee</div>
              <div className="col-sm-8">assdd</div>
            </div>
            <div className="row my-2">
              <div className="col-sm-4">5. DG Form 1</div>
              <div className="col-sm-8">assdd</div>
            </div>
            <div className="row my-2">
              <div className="col-sm-4">6. RT PCR Report</div>
              <div className="col-sm-8">assdd</div>
            </div>
            <div className="row my-2">
              <div className="col-sm-4">7. OK To Board/Visa</div>
              <div className="col-sm-8">assdd</div>
            </div>
          </div>
        ) : (
          <div>asdfghjk,.</div>
        )} */}

        <div className="container">
          <div className="row p-4">
            <div className="col-sm-1"></div>
            <div className="col-sm-5 text-center br-8 border p-3">
              <h3 className="text-uppercase ">International</h3>
              <hr className="w-100" />
              <Carousel
                showArrows={false}
                showIndicators={false}
                showThumbs={false}
                showStatus={false}
                autoPlay={true}
                interval={1100}
                infiniteLoop={true}
                swipeable={true}
              >
                {data.elgibilityslider.map((val) => (
                  <div>
                    <h5 className="text-left ms-5">{val.heading}</h5>
                    <ol type="a">
                      <li className="text-justify lh-base">{val.list1}</li>
                      {val.list2 ? (
                        <li className="text-justify lh-base my-3">
                          {val.list2}
                        </li>
                      ) : (
                        ""
                      )}
                      {val.list3 ? (
                        <li className="text-justify lh-base">{val.list3}</li>
                      ) : (
                        ""
                      )}
                    </ol>
                  </div>
                ))}
              </Carousel>
            </div>
            <div className="col-sm-5 border br-8 p-3">
              <h3 className="text-center text-uppercase">Domestic</h3>
              <hr className="w-100" />
              <h5 className="">Continuous Discharge Certificate</h5>
              <ol type="a">
                <li>Shall be valid for a period of ten years.</li>
              </ol>
            </div>
            <div className="col-sm-1"></div>
          </div>
        </div>
      </div>
      <div
        className="contactus_parallax3 my-2"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="container ">
          <div className="row mb-2">
            <div className="col-sm-7 ">
              <h1 className="f-35 text-white text-capitalize ">
                Seamless travel on the high seas, powered by automation
              </h1>
            </div>

            <Button
              textTransform="uppercase"
              padding="12px"
              fw="700"
              border="1px solid  #e77000"
              bg=" #e77000"
              color="#ffffff"
              transition="all 0.5s"
              className="custom_btn  mt-3 "
            >
              CONTACT us
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Eligibility;
