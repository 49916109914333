import React from "react";
import "./index.css";
import Input from "../input";
import logo from "../../assests/images/logo/oceanmatelogo.png";
import { FaFacebook } from "react-icons/fa";
import {
  AiOutlineTwitter,
  AiFillYoutube,
  AiOutlineInstagram,
  AiFillLinkedin,
} from "react-icons/ai";
import List from "../list";
import data from "../../assests/data/data";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import Button from "../../components/button";
import { CiLocationOn } from "react-icons/ci";

const Footer = () => {
  return (
    <div className="container-fluid footer p-5">
      <div className="row">
        <div className="col-sm-2 mx-3">
          <div className="logo text-center">
            <img src={logo} width={130} />
          </div>
          <p className="ls-1 text-white text-justify f-18">
            Providing mariners and crew with flexible travel options
          </p>
          <div className="d-flex align-items-center justify-content-between p-2">
            <div className="social_icon pointer">
              <a href="https://www.facebook.com/oceanmate.in">
                <FaFacebook color="#ffffff" size={25} />
              </a>
            </div>

            <div className="social_icon pointer">
              {" "}
              <a href="https://twitter.com/ocean_mate">
              <AiOutlineTwitter color="#ffffff" size={25} />
              </a>         
            </div>
            
            <div className="social_icon pointer">
            <a href="https://www.instagram.com/oceanmate.in/">
              <AiOutlineInstagram color="#ffffff" size={25} />
              </a>
            </div>

            <div className="social_icon pointer ">
            <a href="https://www.linkedin.com/company/oceanmate">
              <AiFillLinkedin color="#ffffff" size={25} />
              </a>
            </div>
          </div>
        </div>
        <div className="col-sm-1  "></div>
        <div className="col-sm-3 ">
          <div className="ls-1">
            {/* <CiLocationOn color="white"/>  */}
            <List
              heading="our Address "
              data={data.footerlist}
              color="#ffffff"
            />
          </div>
        </div>
        {/* <div className="col-sm-1"></div> */}
        <div className=" quickk col-sm-2 mx-3 ">
          <h5 className="text-primary f-17 mt-5">
            {" "}
            <div className="quick"></div>QUICK LINKS
          </h5>
          {data.footerlist1.map((val) => (
            <NavLink
              to={val.url}
              style={{ display: "flex", flexDirection: "column" }}
              className="footerlinks  ls-1 "
            >
              {val.name}{" "}
            </NavLink>
          ))}
        </div>

        <div className="newss ls-1 col-sm-3 mx-3">
          <List
            heading="News letter"
            data={data.footerlist2}
            color="#ffffff "
          />

          <Input
            type="email"
            width="100%"
            height="30px"
            placeholder="Enter your email"
          ></Input>
          <Button
            className="butt"
            color="#ffffff"
            bg="#e77000"
            border="1px solid #e77000"
            padding="1px"
            width="31%"
          >
            SUBMIT
          </Button>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 mt-5">
            <p className="copy text-white text-center">
              Copyright &copy; <a href="/">OceanMate</a>. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
