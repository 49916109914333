import React from "react";
const TeamDescription = (props) => {
  return (
    <div>
      <div className="conatiner-fluid">
        <div className="row">
          <div className="col-sm-4">
            <img src={props.img} width="100%" />
          </div>
          <div className="col-sm-8">
            <h2>{props.name}</h2>
            <p className="text-secondary f-20">{props.position}</p>
            <p>{props.info}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamDescription;
