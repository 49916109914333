let Teamdata = {
  member1: "Akshay Ankit",
  designation1: "CEO",
  desc1:
    "Mr. Akshay, a marine engineer from DMET in Kolkata, has been a frequent seafarer most of his life. Oceanmate, being his brainchild, was well-versed in the challenges and shortfalls of the marine travel industry, which is filled with an enormous number of agents without any organized and transparent ticketing system. Though the shipping and marine ecosystem consists of many brands providing ticketing and related services, it faces many challenges due to the conventional ticketing system, which comes with an inherent delay problem. This is where Mr. Akshay came up with Oceanmate to create a transparent and smooth automated ticketing system for the shipping and marine sectors. ",
  member2: "Swati Ranjan",
  designation2: "Director",
  desc2:
    "One of the founding team members of Oceanmate, Ms. Swati, is an experienced HR professional and was associated with many well-known travel brands before entering the entrepreneurship journey. Ms. Swati is handling the entire team-building process at Oceanmate. Structuring and brainstorming ideas within Oceanmate have turned the concept of Oceanmate into reality, and Ms. Swati has been an integral part of this process.  ",
  
    member3: " Nilesh Kumar",
  designation3: "Director",
  desc3:
    "A performance-driven and award-winning entrepreneur with a Masters Degree in travel and tourism from NSHM, Durgapur has been in the travel and tourism sector for many years. During his initial days, he was associated with renowned brands like Yatra, SOTC, Cox, and Kings. Being one  of the founding members of Oceanmate, Mr. Nilesh brings his exceptional knowledge and expertise to create a smooth and transparent process to eradicate the challenges and gaps in the marine ecosystem.",
  
    member4: " Piyush Kumar",
  designation4: "Director",
  desc4:
    "Mr. Piyush, who has been a seafarer for the past 10 years and has run a ship management business for the past 8 years, joined Oceanmate with the goal of making air travel simple and convenient for both his company and seafarers who need to travel to and from their ships for work. He brings a wealth of knowledge and experience to the company as an entrepreneur with interests in ship management, agriculture, and cloud security. We are committed to making travel for seafarers effortless and stress-free because we are aware of the particular requirements and difficulties they face. We look forward to serving seafarers all over the world and achieving our objective of becoming the most popular provider of air tickets for seafarers.  ",
  member5: "Prashant Gyan",
  designation5: "Director",
  desc5:
    "An accomplished entrepreneur with a demonstrated and vast work experience in IT and services industry. With expertise in Python, SQL, Web Applications, Business Development and Agile Methodologies. Dedicatedly focusing on challenges and solutions for server, compliance and automation management in various sectors for last 14 years.    ",
};
export default Teamdata;
